import React from 'react'
import Link from '../link'
import LeftArrow from '../../icons/left-arrow'


export default function ArrowLink({ name, hover, link, className, classMain, ...props }) {

    if (link) {
        return (
            <Link to={link} className={'flex al-i-c  arrow--link ' + classMain} {...props}>
                <span className={'button-text mr10 ' + className}>{name}</span>
                <LeftArrow hoverState={hover} />
            </Link>
        )
    } else {
        return (
            <div className='flex al-i-c  arrow--link '>
                <span className={'button-text mr10 ' + className}>{name}</span>
                <LeftArrow hoverState={hover} />
            </div>
        )
    }

}
