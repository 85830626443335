import React from 'react'
import ArrowLink from '../arrow-link/arrow-link'

export default function Title({ className,children,arrowClass,link }) {
    return (
        <div className={'flex ' + className}>
            {children}
            <div className={'flex ' +arrowClass}>
            <ArrowLink link={link} name='see all' />
            </div>
         
        </div>
    )
}
