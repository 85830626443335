import React , {memo} from 'react'

import OperationBanner from './operation-banner'

function OperationsList({operations}) {
    return (operations.map((operation,index) => {
            return <OperationBanner key={index} operation={operation} />
          })
    )
}


export default memo(OperationsList,() => true)