import React, { Children, memo } from 'react'
import ContentContainer from '../content-container/content-container'
import FadeInView from '../fade-inview/fade-inview'
import SmallSpacer from '../spacer/small-spacer'
import Spacer from '../spacer/spacer'
import Title from '../title/title'

function Quote({ quote, max = 'max-450', children }) {
    return (
        <ContentContainer>
            <div className='flex flex-c h100vh bg-bark'>
                <SmallSpacer />
                <SmallSpacer />
                <FadeInView className='flex-grow-1 flex-c  flex    ju-c al-i-c     '>
                    <SmallSpacer />
                    <h4 className={'large-sentence light-white ' + max}>{quote}</h4>
                    <SmallSpacer />
                    {children}

                </FadeInView>


                <SmallSpacer />

            </div>
        </ContentContainer>
    )
}


export default memo(Quote, () => true)