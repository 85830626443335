import React from 'react'
import Layout from '../components/layout'
import ScalingBanner from '../components/scaling-banner/scaling-banner'
import Seo from '../components/seo'
import SquareScalingBanner from '../components/square-scaling-banner/square-scaling-banner'
import Spacer from '../components/spacer/spacer'
import SmallSpacer from '../components/spacer/small-spacer'
import OperationsList from '../components/operations/operations-list'
import { graphql } from 'gatsby'
import Quote from '../components/quote/quote'

function Svg () {
  return(
    <svg width="1235" height="229" viewBox="0 0 1235 229" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M77.3401 228.459C97.7607 228.459 110.888 215.331 110.888 194.91V54.8836C110.888 35.9216 96.3021 21.3355 77.3401 21.3355H62.7539C43.792 21.3355 29.2058 35.9216 29.2058 54.8836V194.91C29.2058 215.331 42.3333 228.459 62.7539 228.459H77.3401ZM62.7539 197.828C61.0036 197.828 59.8367 196.953 59.8367 194.91V54.8836C59.8367 52.8415 61.0036 51.9663 62.7539 51.9663H77.3401C79.0904 51.9663 80.2573 52.8415 80.2573 54.8836V194.91C80.2573 196.953 79.0904 197.828 77.3401 197.828H62.7539Z" fill="#EEEAD9"/>
<path d="M277.202 51.6524C277.202 34.2804 263.839 20.9174 246.467 20.9174H202.369V208H230.431V141.185H246.467C265.175 141.185 277.202 129.158 277.202 110.45V51.6524ZM246.467 48.9798C248.071 48.9798 249.14 49.7815 249.14 51.6524V110.45C249.14 112.321 248.071 113.122 246.467 113.122H230.431V48.9798H246.467Z" fill="#EEEAD9"/>
<path d="M427.131 23.0005H367.198V187.523H427.131V162.845H391.876V115.133H424.781V90.4549H391.876V47.679H427.131V23.0005Z" fill="#EEEAD9"/>
<path d="M569.442 45.3022C569.442 32.2181 559.378 22.1535 546.294 22.1535H513.08V163.059H534.216V109.716H539.852L549.313 163.059H572.462L558.774 106.697C565.417 102.872 569.442 95.8267 569.442 86.5672V45.3022ZM546.294 43.2892C547.501 43.2892 548.307 43.8931 548.307 45.3022V86.5672C548.307 87.9763 547.501 88.5802 546.294 88.5802H534.216V43.2892H546.294Z" fill="#EEEAD9"/>
<path d="M645.466 144.059H663.927L666.389 125.07H685.201L687.662 144.059H706.123L688.542 20.9865H663.048L645.466 144.059ZM675.707 51.7545L682.74 106.61H668.674L675.707 51.7545Z" fill="#EEEAD9"/>
<path d="M768.031 19.746V36.5929H784.878V132.059H801.725V36.5929H818.572V19.746H768.031Z" fill="#EEEAD9"/>
<path d="M907.72 21.0305H892.909V119.771H907.72V21.0305Z" fill="#EEEAD9"/>
<path d="M1011.61 107.617C1020.25 107.617 1025.81 102.061 1025.81 93.4182V34.152C1025.81 26.1264 1019.64 19.9528 1011.61 19.9528H1005.44C997.412 19.9528 991.238 26.1264 991.238 34.152V93.4182C991.238 102.061 996.794 107.617 1005.44 107.617H1011.61ZM1005.44 94.6529C1004.7 94.6529 1004.2 94.2825 1004.2 93.4182V34.152C1004.2 33.2877 1004.7 32.9173 1005.44 32.9173H1011.61C1012.35 32.9173 1012.85 33.2877 1012.85 34.152V93.4182C1012.85 94.2825 1012.35 94.6529 1011.61 94.6529H1005.44Z" fill="#EEEAD9"/>
<path d="M1131.03 20.4017H1119.99V60.3551L1111.06 20.4017H1098.97V94H1110.01V54.0466L1118.94 94H1131.03V20.4017Z" fill="#EEEAD9"/>
<path d="M1210.18 29.1572C1210.18 28.5302 1210.54 28.2616 1211.07 28.2616H1215.55C1216.09 28.2616 1216.45 28.5302 1216.45 29.1572V40.8005H1225.85V29.1572C1225.85 23.3355 1221.37 18.8573 1215.55 18.8573H1211.07C1205.25 18.8573 1200.77 23.3355 1200.77 29.1572V35.8745C1200.77 42.0544 1202.92 45.1892 1205.79 48.7717L1213.31 58.2655C1215.37 60.9524 1216.45 62.2959 1216.45 66.3263V72.148C1216.45 72.7749 1216.09 73.0436 1215.55 73.0436H1211.07C1210.54 73.0436 1210.18 72.7749 1210.18 72.148V59.609H1200.77V72.148C1200.77 78.4174 1204.81 82.4478 1211.07 82.4478H1215.55C1221.82 82.4478 1225.85 78.4174 1225.85 72.148V65.4306C1225.85 58.982 1222.99 55.3995 1219.94 51.5482L1212.6 42.144C1210.99 39.9944 1210.18 39.0092 1210.18 34.9789V29.1572Z" fill="#EEEAD9"/>
</svg>

  )
}
export default function Operations({data :{operation,datoCmsOperationPage,expertisePage}}) {
    return (
        <Layout>
            <Seo title='Operations'/>
            <ScalingBanner video={datoCmsOperationPage.videoLink} image={datoCmsOperationPage.heroImage} svg={<Svg/>} title='Operations'/>
            <Quote max='max-800' quote={datoCmsOperationPage.introduction}/>
            <OperationsList operations={operation.operations}/>
            <Spacer/>
            <SquareScalingBanner image={expertisePage.heroImage} link='/expertise'>
                <h3 className='small-serif'>next page</h3>
                <SmallSpacer/>
                <h2 className='h1 m-h3'>expertise</h2>
            </SquareScalingBanner>
            <Spacer/>
           

        </Layout>
    )
}


export const query = graphql`
  query OperationPage {
    expertisePage:datoCmsExpertise {
    heroImage {
      gatsbyImageData
    }
  }
    datoCmsOperationPage {
    heroImage {
      gatsbyImageData
      url
    }
    videoLink
    introduction
  }
    operation:allDatoCmsOperation(sort: { fields: name}){
      operations:nodes{
        ...OperationBanner
      }
    }
  }
`