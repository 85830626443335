import { graphql } from 'gatsby'
import Link from '../link'
import React, { memo } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

function OperationBanner({ operation: { name, slug, heroImage } }) {

    return (

        <Link to={`/operations/${slug}`} className='ju-c al-i-c h100vh re'>
            <div className='bg-wrapper w100 flex ju-c al-i-c h100vh '>
                <h1 className='z-2 h1 pointer-none'>{name}</h1>
                <div className="scale--wrapper scaling--wrapper bg-image" >
                    <div className='bg-image bg-parallax z-1' >
                        <GatsbyImage image={heroImage.gatsbyImageData} className='bg-image' />
                    </div>
                </div>
            </div>
        </Link>

    )
}

export default memo(OperationBanner)


export const query = graphql`
   fragment OperationBanner on DatoCmsOperation{
        slug
        name
        heroImage {
            gatsbyImageData
            url
        }
   }
`